<template>
  <a-card :bordered="false" class="card-area">
    <a-form layout="horizontal">
      <span class="fold">
        <a-row>
          <a-col :md="5" :sm="5">
            <a-form-item
                label="下单人"
                :labelCol="{span: 4}"
                :wrapperCol="{span: 16, offset: 1}">
              <a-input v-model.trim="queryParams.username"/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="5">
            <a-form-item
                label="接单人"
                :labelCol="{span: 4}"
                :wrapperCol="{span: 16, offset: 1}">
              <a-input v-model.trim="queryParams.musername"/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="5">
            <a-form-item
                label="订单号"
                :labelCol="{span: 4}"
                :wrapperCol="{span: 16, offset: 1}">
              <a-input v-model.trim="queryParams.orderNo"/>
            </a-form-item>
          </a-col>
          <a-col :md="5" :sm="5">
            <a-form-item
                label="付款状态"
                :labelCol="{span: 8}"
                :wrapperCol="{span: 8, offset: 1}">
              <a-select v-model="queryParams.status" placeholder="选择过滤字段" style="width:100%"
                        @change="searchTable()">
                <a-select-option v-for="item in payRequestStatus" :value="item.value"
                                 :key="item.value">{{ item.label }}
                </a-select-option>
              </a-select>

            </a-form-item>
          </a-col>

          <a-col :md="4" :sm="4">
            <a-form-item
                label="服务状态"
                :labelCol="{span: 8}"
                :wrapperCol="{span: 8, offset: 1}">
              <a-select v-model="queryParams.cstatus" placeholder="选择过滤字段" style="width:100%"
                        @change="searchTable()">
                <a-select-option v-for="item in inagencyNStatus" :value="item.value"
                                 :key="item.value">{{ item.label }}
                </a-select-option>
              </a-select>

            </a-form-item>
          </a-col>
        </a-row>
      </span>
      <span style="float: right; margin-top: 3px;">
          <a-button type="primary" @click="searchTable()" :loading="showLoading">查询</a-button>
          <a-button style="margin-left: 8px" @click="reset()">重置</a-button>
      </span>
    </a-form>
    <a-table :dataSource="tableData" rowKey="id" size="small" :bordered="true" :columns="columns"
             :scroll="{ x: 1000 }">
      <template slot="imghead" slot-scope="text">
        <div v-if="text.uhead" class="img-list">
          <el-image style="width: 40px; height: 40px" :src="(text.uhead || '') | fullImgPath" fit="contain"
                    :preview-src-list="(text.uhead || '').split(',')"/>
        </div>
      </template>
      <template slot="status" slot-scope="text,record">
        <a-tag :color="record.status | dictName('payRequestStatus', 'color')">
          {{ record.status | dictName("payRequestStatus") }}
        </a-tag>
      </template>
      <template slot="cstatus" slot-scope="text,record">
        <a-tag :color="record.cstatus | dictName('inagencyNStatus', 'color')">
          {{ record.cstatus | dictName("inagencyNStatus") }}
        </a-tag>
      </template>
      <template slot="operation" slot-scope="text, record">
        <a-button v-if="record.status == 2" type="primary" @click="initiateRefund(record)">发起退款</a-button>
        <a-button type="primary" @click="cancelOrder(record)" style="margin-left: 10px;">取消订单
        </a-button>
      </template>
    </a-table>
  </a-card>
</template>
<script>
import {DICT} from "@/utils/dict";
import {message} from "ant-design-vue";

export default {
  name: "readerList",
  data() {
    return {
      tableData: [],
      value: '',
      showLoading: false,
      queryParams: {
        fwhere: "2",
        username: '',
        musername: '',
        orderNo: '',
        status: undefined,
        cstatus: undefined
      }
    };
  },
  created() {
    this.searchTable();
  },
  computed: {
    payRequestStatus() {
      return DICT['payRequestStatus']
    },
    inagencyNStatus() {
      return DICT['inagencyNStatus']
    },
    columns() {
      return [{
        title: "用户id",
        width: 50,
        align: "center",
        dataIndex: "auid",
      }, {
        title: "下单人",
        width: 80,
        align: "center",
        dataIndex: "username",
      }, {
        title: "接单人",
        width: 80,
        align: "center",
        dataIndex: "musername",
      }, {
        title: "付款状态",
        width: 80,
        align: "center",
        dataIndex: "status",
        scopedSlots: {customRender: "status"}
      }, {
        title: "订单号",
        width: 120,
        align: "center",
        dataIndex: "orderno",
      }, {
        title: "金额",
        width: 80,
        align: "center",
        dataIndex: "amount",
      }, {
        title: "微信支付单号",
        width: 160,
        align: "center",
        dataIndex: "transactionid",
      }, {
        title: "创建日期",
        width: 80,
        align: "center",
        dataIndex: "createdate",
      }, {
        title: "服务状态",
        width: 80,
        align: "center",
        dataIndex: "cstatus",
        scopedSlots: {customRender: "cstatus"}
      }, {
        title: "关联公司",
        width: 120,
        align: "center",
        dataIndex: "standardName",
      }, {
        title: "需求说明",
        width: 120,
        align: "center",
        dataIndex: "mark",
      }, {
        title: "有效期",
        width: 85,
        align: "center",
        dataIndex: "vdate",
      }, {
        title: '操作',
        width: 160,
        align: 'center',
        dataIndex: 'operation',
        scopedSlots: {customRender: 'operation'}
      }];
    }
  },
  watch: {
    '$route.query.id': {
      handler() {
        if (this.$route.query.id) {
          this.queryParams.id = this.$route.query.id;
        }
        this.searchTable();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    cancelOrder(row) {
      message.info("没有完成相关功能")
      console.log(row)
    },
    initiateRefund(row) {
      message.info("没有完成相关功能")
      console.log(row)
    },
    reset() {
      this.queryParams = {
        fwhere: "2",
        username: '',
        orderNo: '',
        id: undefined,
        status: undefined
      }
    },
    searchTable() {
      let that = this;
      that.showLoading = true;
      that.$postJson("jobIntro/insiderOrder", this.queryParams).then((res) => {
        that.showLoading = false;
        that.tableData = res.data;
      });
      that.queryParams.id = undefined;
    }
  }
}
</script>
<style>
.el-row {
  margin-bottom: 20px;
}

.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;
}

.bg-purple {
  background: #d3dce6;
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}
</style>
